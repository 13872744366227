<template>
  <div>
    <div v-if="loadingRecoverSponsorCode">
      <div class="ml-2">
        <clip-loader :loading="loadingRecoverSponsorCode" color="primary"></clip-loader>
      </div>
    </div>
    <div v-else class="m-4">
      <div class="pt-5 pb-5">
        <vx-card>
          <div slot="no-body" class="p-8">
            <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div>
                  <h1>{{ user.person.firstname }} votre lien de parrainage est prêt !</h1>
                  <p>
                    Vous pouvez le partager dès maintenant avec vos amis et gagner
                    des primes.
                  </p>
                </div>
              </vs-col>
            </vs-row>

            <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12" class="mt-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div class="aab-vie-sponsorship-input-container mr-4">
                  <strong>{{ link }}</strong>
                </div>
                <div>
                  <vs-button
                    v-clipboard:copy="link"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >Copier</vs-button>
                </div>
              </vs-col>
            </vs-row>
            
            <div class="aab-vie-share-btn mt-6">
              <div class="mb-2">
                <p>Partager sur les réseaux sociaux</p>
              </div>
              <div class="flex">
                <ShareNetwork
                  class="m-2"
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{ backgroundColor: network.color }"
                  :url="link"
                  :title="description"
                  :description="description"
                  :quote="description"
                >
                  <vs-button
                    :color="network.color"
                    icon-pack="feather"
                    :icon="network.icon"
                  >{{ network.name }}</vs-button>
                </ShareNetwork>
              </div>
            </div>
          </div>
        </vx-card>
      </div>

      <div class="pt-5 pb-5 flex justify-center">
        <vs-button
          icon-pack="feather"
          icon="icon-download"
          color="primary"
          @click="checkViewFile"
        >Télécharger</vs-button>
      </div>

      <div class="pt-5 pb-5 flex justify-center">
        <h1>Statistiques</h1>
      </div>

      <div class="pb-5">
        <vs-row vs-w="12" vs-align="center">
          <vs-col
            class="p-2"
            vs-lg="4"
            vs-md="4"
            vs-sm="6"
            :key="stat.label"
            v-for="stat in statsList"
          >
            <statistics-card-line
              hideChart
              icon="BarChart2Icon"
              icon-right
              :statistic="stat.value"
              :statisticTitle="stat.label"
            />
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VueSocialSharing from "vue-social-sharing";

export default {
  data() {
    return {
      targetsSocialShared: ["facebook", "twitter", "whatsapp", "linkedin"],
      description: "Votre lien de parrainage de APS Bénin: ",
      link: "https://app.aps-benin.com/pr/5WYt5n_",
      statsList: [],
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "icon-facebook",
          color: "#1877f2"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "icon-linkedin",
          color: "#007bb5"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "icon-twitter",
          color: "#1da1f2"
        }
      ],
      loadingDownloadFile: false,
      loadingRecoverSponsorCode: false,
      error: null
    };
  },

  components: {
    StatisticsCardLine,
    ClipLoader,
    VueSocialSharing
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  mounted() {
    this.link = `https://aabvie.net/?code=${this.user.userCode}`;
    this.description = this.user.person.firstname + " " + this.description;
    this.getStats();
  },
  methods: {
    ...mapActions("auth", ["applyParrainStats"]),
    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    async getStats() {
      let userStat = {
        userId: this.user.id,
        parentCode: this.user.parentCode
      };

      this.loadingRecoverSponsorCode = true;
      try {
        let stats = await this.applyParrainStats(userStat);
        this.statsList = stats.stats;
        this.loadingRecoverSponsorCode = false;

        this.error = false;
      } catch (err) {
        this.loadingRecoverSponsorCode = false;
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            this.acceptAlert(
              "danger",
              "Mise à jour profil",
              "Une erreur s'est produite"
            );
          }
        }
      }
    },

    onCopy() {
      this.$vs.notify({
        title: "Succès",
        text: "Lien copier avec succès",
        color: "success",
        iconPack: "feather",
        position: "bottom-right",
        icon: "icon-check-circle"
      });
    },

    onError() {
      this.$vs.notify({
        title: "Echec",
        text: "Erreur lors de la copie du lien",
        color: "danger",
        iconPack: "feather",
        position: "bottom-right",
        icon: "icon-alert-circle"
      });
    },

    downloadSponsorshipRpules() {
      this.loadingDownloadFile = true;
      try {
        var sponsorship = require("file-saver");
        sponsorship.saveAs(
          `${this.$config.serverURL}/downloads/${data.fileName}`,
          `${data.name}`
        );
        this.loadingDownloadFile = false;
      } catch (error) {
        this.loadingDownloadFile = false;
      }
    },
    checkViewFile() {
      window.open(
        "https://app.aps-benin.com/static/media/REGLEMENT_PARRAINAGE.0108a124.pdf",
        "_blank"
      );
      // window.open(this.showFileData(item.filename), "_blank");
    },
    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    }
  },
  watch: {
    $route: "recoverSponsorCode"
  }
};
</script>

<style >
.aab-vie-share-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aab-vie-share-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aab-vie-sponsorship-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
.vs-con-input {
  width: 100%;
}
</style>
